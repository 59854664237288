html,
body {
  height: 100%;
  margin: 0;
}

.loading {
  display: flex;
  flex-direction: column; /* Ensure elements stack vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  height: 100vh;
  text-align: center; /* Optional: center text */
}

.loading-container {
  position: relative;
  width: 300px; /* Adjust the width as needed */
  height: 300px; /* Adjust the height as needed */
  overflow: hidden;
  margin-top: 20px; /* Add some spacing between the heading and the image */
}

.loading-image {
  width: 100%;
  height: 100%;
  animation: loadingEffect 3s infinite;
}

@keyframes loadingEffect {
  0% {
    filter: blur(0) brightness(1);
  }
  50% {
    filter: blur(5px) brightness(2);
  }
  100% {
    filter: blur(0) brightness(0.5);
  }
}
